<template>
  <div class="guard">
    <div class="main">
      <div class="big_logo">
        <img src="@/assets/bgi.png" alt="" class="bgi">
      </div>
      <div class="doing-what">
        <div class="h3">
          <h2>家长监护</h2>
        </div>
        <div class="content">
          <h2>家长监护</h2>
          <p>网络游戏未成年人家长监护工程</p>
          <p>“网络游戏未成年人家长监护工程”是一项由中华人民共和国文化部指导，网络游戏企业自主发起并参与实施，旨在加强家长对未成年人参与网络游戏的监护，引导未成年人健康、绿色参与网络游戏，构建和谐家庭关系的行业自律活动。它提供了一种切实可行的方法，一种家长实施监控的管道，使家长纠正未成年子女沉迷游戏的行为成为可能。该项社会公益行动充分反映了中国网络游戏行业高度的社会责任感，对未成年玩家合法权益的关注及对用实际行动营造和谐社会的愿望。 申请监护方式 未成年人健康参与网络游戏提示 随着网络在青少年中的普及，未成年人接触网络游戏已经成为普遍现象。为保护未成年人健康参与游戏，在政府进一步加强行业管理的前提下，家长也应该加强监护引导。为此，我们为未成年人参与网络游戏提供以下意见：主动控制游戏时间。 游戏只是学习、生活的调剂，要积极参与线下的各类活动，并让父母了解自己在网络中的行为和体验。 不参与可能耗费较多时间的游戏设置。 不玩大型角色扮演类游戏，不玩有PK类设置的游戏。在校学生每周玩游戏不超过2小时，每月在游戏中的花费不超过10元。不要将游戏当做精神寄托。尤其在现实生活中遇到压力和挫折时，应多与家人朋友交流倾诉，不要只依靠游戏来缓解压力。养成积极健康的游戏心态。克服攀比、炫耀、仇恨和报复等心理，避免形成欺凌弱小、抢劫他人等不良网络行为习惯。 注意保护个人信息。 包括个人家庭、朋友身份信息，家庭、学校、单位地址，电话号码等，防范网络陷阱和网络犯罪。</p>
          <h3>用户申请</h3>
          <h3>申请条件</h3>
          <p>1、申请人须为被监护未成年人的法定监护人；</p>
          <p>2、申请人的被监护人年龄小于18周岁；</p>
          <p>3、申请人须为大陆公民，不含港、澳、台人士。</p>
          <h3>申请方式</h3>
          <p>1、电话咨询，监护人可以直接来电咨询相关服务。客服电话：400-903-1158</p>
          <h3>监督方式</h3>
          <p> 1、屏蔽账号 根据监护人意愿屏蔽相关游戏应用登陆。</p>
          <p> 2、账号动态监控：</p>
          <p> 登陆、修改密码等操作将发短信至监护人手机。</p>
          <p> （1）账号已经有认证手机的，将监护人手机替换为认证手机；</p>
          <p> （2）账号未认证手机，将监护人手机绑定成认证手机；</p>
          <h3>其他要求</h3>
          <p>1、申请人应提交较完备的申请材料，对未提供的信息要及时补充；可请熟知电脑、互联网、游戏的人员进行协助，以便提供详实资料；</p>
          <p>2、申请人应保证提交的信息真实有效；对于提供虚假信息或伪造证件，监护关系证明书追究法律责任的权利。</p>
          <h3>申请家长监护服务</h3>
          <p class="download" @click="download">打包下载相关附件</p>
          <p>附件一： 被监护人信息表18</p>
          <p>附件二： 监护人信息表</p>
          <p>附件三： 网络游戏未成年人家长监护申请书</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Guardianship',
  mounted() {
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
  },
  methods: {
    download() {
      window.location.href = 'https://thcdn.gggamedownload.com/apk/msg.zip'
    }
  }
}
</script>

<style lang="scss" scoped>
.download {
  cursor: pointer;
  color: #318efd;
}
.guard {
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
}
.content {
  width: 1200px;
  margin: 0 auto;
  border: 30px solid #fff;
  margin-top: 40px;
  padding: 20px;
}
.doing-what {
    background-color: #f5f5f5;

  .h3 {
    height: 60px;
    display: flex;
    align-items: center;
    line-height: 60px;
    width: 1200px;
    margin: 0 auto;
    h2 {
    border-left: 5px solid #3a79d4 ;
      padding-left: 30px;
      font-weight: 400;
    }
  }
}
p {
  line-height: 45px;
}
h2,h3 {
  line-height: 40px;
}
</style>
